














































































































































































import Vue from "vue";
import Component from "vue-class-component";

import LayoutComponent from "@/components/Layout.vue";
import { Watch } from "vue-property-decorator";

import { initializeTheme } from "@/utils/ThemeUtil";
import { getUserId } from "@/utils/ParameterUtil";
import { UiState, ChangeUiState } from "@/UiState";
import { OCRServiceProvider } from "@/services/remote/OcrService";
import { NotificationService } from "@/services/NotificationService";
import { InputFieldRule, SelectItem } from "@/types/VuetifyTypes";
import {
  OCRSex,
  FIRST_NAME_MIN_LENGTH,
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MIN_LENGTH,
  LAST_NAME_MAX_LENGTH,
  EMAIL_MAX_LENGTH,
  EMAIL_PATTERN,
  MOBILE_MAX_LENGTH,
  TEAM_NAME_MAX_LENGTH,
  COMMENT_MAX_LENGTH,
} from "@/types/OCRTypes";

@Component({
  components: {
    LayoutComponent,
  },
})
export default class ChangeView extends Vue {
  showView = false;
  showDayOfBirthPicker = false;

  sendBtnDisabled = true;

  uiState: ChangeUiState = UiState.getInstance().change;

  get availableSexItems(): SelectItem[] {
    return [
      {
        text: this.$t("theme." + this.uiState.theme + ".sex.Male") as string,
        value: OCRSex.Male,
      },
      {
        text: this.$t("theme." + this.uiState.theme + ".sex.Female") as string,
        value: OCRSex.Female,
      },
      {
        text: this.$t("theme." + this.uiState.theme + ".sex.Other") as string,
        value: OCRSex.Other,
      },
    ];
  }

  get firstNameRules(): InputFieldRule[] {
    return [
      (val: string): boolean | string => {
        if (val === undefined || val === null) {
          return this.$t(
            "theme." +
              this.uiState.theme +
              ".changeValidation.firstNameRequired"
          ) as string;
        }

        if (val.trim().length < FIRST_NAME_MIN_LENGTH) {
          return this.$t(
            "theme." +
              this.uiState.theme +
              ".changeValidation.firstNameMinLength"
          ) as string;
        }

        return true;
      },
    ];
  }

  get lastNameRules(): InputFieldRule[] {
    return [
      (val: string): boolean | string => {
        if (val === undefined || val === null) {
          return this.$t(
            "theme." + this.uiState.theme + ".changeValidation.lastNameRequired"
          ) as string;
        }

        if (val.trim().length < LAST_NAME_MIN_LENGTH) {
          return this.$t(
            "theme." +
              this.uiState.theme +
              ".changeValidation.lastNameMinLength"
          ) as string;
        }

        return true;
      },
    ];
  }

  get emailRules(): InputFieldRule[] {
    return [
      (val: string): boolean | string => {
        if (val === undefined || val === null || val.trim().length === 0) {
          return this.$t(
            "theme." + this.uiState.theme + ".changeValidation.emailRequired"
          ) as string;
        }

        return (
          EMAIL_PATTERN.test(val) ||
          (this.$t(
            "theme." + this.uiState.theme + ".changeValidation.emailInvalid"
          ) as string)
        );
      },
    ];
  }

  get firstNameMaxLength(): number {
    return FIRST_NAME_MAX_LENGTH;
  }

  get lastNameMaxLength(): number {
    return LAST_NAME_MAX_LENGTH;
  }

  get emailMaxLength(): number {
    return EMAIL_MAX_LENGTH;
  }

  get mobileMaxLength(): number {
    return MOBILE_MAX_LENGTH;
  }

  get teamnameMaxLength(): number {
    return TEAM_NAME_MAX_LENGTH;
  }

  get commentMaxLength(): number {
    return COMMENT_MAX_LENGTH;
  }

  closeDayOfBirthPicker(value: Date) {
    this.showDayOfBirthPicker = false;
  }

  @Watch("uiState.form.hasChanges")
  onFormChanges() {
    this.sendBtnDisabled = !this.uiState.form.hasChanges;
  }

  onSendBtnClickedHandler(): void {
    this.saveChanges();
  }

  private saveChanges(): void {
    const notificationService = NotificationService.getInstance();
    notificationService
      .indicateLoading(
        this.saveChangesViaApi(),
        this.$t("messages.sendingData") as string
      )
      .then(() => {
        this.loadData();
        this.showChangeSuccessMessage();
      })
      .catch((err: Error) => {
        notificationService.notifyError(err.message);
      });
  }

  showChangeSuccessMessage(): void {
    this.$vuetify.goTo(0);

    const notificationService = NotificationService.getInstance();
    notificationService.notifySuccess(
      this.$t("messages.changedData") as string
    );
  }

  private saveChangesViaApi(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const ocrService = OCRServiceProvider.getInstance().getService();
      ocrService
        .saveChanges(
          this.uiState.ocrChange.userChange.uuid,
          this.uiState.getChangeData()
        )
        .then(resolve)
        .catch(reject);
    });
  }

  loadDataFromApi(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      getUserId(this)
        .then((userId) => {
          const ocrService = OCRServiceProvider.getInstance().getService();
          ocrService
            .getChangeData(userId)
            .then((data) => {
              this.uiState.ocrChange = data;
              resolve();
            })
            .catch(reject);
        })
        .catch(reject);
    });
  }

  loadData(): void {
    const notificationService = NotificationService.getInstance();
    notificationService
      .indicateLoading(
        this.loadDataFromApi(),
        this.$t("messages.loadingData") as string,
        true
      )
      .then(() => {
        this.showView = true;
      })
      .catch((err: Error) => {
        this.$router.push("/404").catch((err) => {
          console.log("required param missing");
        });
      });
  }

  mounted(): void {
    initializeTheme(this);
    this.loadData();
  }
}
